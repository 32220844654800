var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tui tuim ui card" },
    [
      _c("div", { staticClass: "tui tuim ui header" }, [
        _c("h2", {
          staticClass: "card-title",
          domProps: { textContent: _vm._s(_vm.title) }
        }),
        _c(
          "button",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.edit,
                expression: "!edit"
              }
            ],
            staticClass: "btn btn-edit",
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.editShow($event)
              }
            }
          },
          [
            _c("t-icon", {
              staticClass: "icon",
              attrs: { icon: ["far", "edit"] }
            })
          ],
          1
        )
      ]),
      _vm._t("content"),
      _vm._t("actions")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }