<template>
  <div class="tui tuim ui card">
    <div class="tui tuim ui header">
      <h2 class="card-title" v-text="title" />
      <button v-show="!edit" class="btn btn-edit" @click.prevent="editShow">
        <t-icon class="icon" :icon="['far', 'edit']" />
      </button>
    </div>
    <slot name="content" />
    <slot name="actions" />
  </div>
</template>

<script>
export default {

  name: "TFormCard",

  props: {

    title: {
      type: String,
      default: ""
    },

    editShow: {
      type: Function
    },

    edit: {
      type: Boolean,
      default: false
    }

  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/colors';

.tuim.tui.ui.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
  border: none;

  .card-title {
    color: $gray-700;
  }

  .btn.btn-edit {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    align-self: flex-start;
    padding: 0;
    transition: all 500ms ease;

    .icon {
      color: #00B7FE;
      margin-right: 0px;
      font-size: 18px;
      transition: all 500ms ease;
    }
  }
}

.tui.tuim.ui.card.item.history:focus,
.tui.tuim.ui.card.item.history:hover {
  box-shadow: none;
  z-index: inherit;
}

.tui.tuim.ui.card {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;

  .content {
    border-top: none;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    transition: all 500ms ease;

    .button {
      margin-left: 20px;
      transition: all 500ms ease;
    }
  }
}
</style>
